<template>
  <div>
    <div class="header">
      <img width="100%" src="../../assets/mobileSpeaker/header.png" alt="" />
    </div>
    <div class="banner">
      <h1>{{ this.$t("EMEET.EmetOptimized") }}</h1>
      <p>{{ this.$t("EMEET.TheExclusiveAI") }}</p>
    </div>
    <ul class="content">
      <li>
        <h2>{{ this.$t("EMEET.ToSee") }}</h2>
        <p>{{ this.$t("EMEET.The360Degree") }}</p>
      </li>
      <li>
        <h2>{{ this.$t("EMEET.SoundClearly") }}</h2>
        <p>{{ this.$t("EMEET.SmartOmnidirectional") }}</p>
      </li>
      <li>
        <h2>{{ this.$t("EMEET.ListenToAll") }}</h2>
        <p>
          {{ this.$t("EMEET.The10W90dB") }}<br />{{ this.$t("EMEET.originalCreation") }}
        </p>
      </li>
      <li>
        <h2>{{ this.$t("EMEET.MaximizingPerformance") }}</h2>
        <p>{{ this.$t("EMEET.BestProfessional") }}</p>
      </li>
    </ul>
    <video
      width="100%"
      src="https://enway-official.s3.ap-northeast-1.amazonaws.com/enway-video/3-2/369camera_video.13946992.mp4"
      loop
      controls
      muted
      autoplay
      playsinline
      webkit-playsinline
      x5-playsinline
    ></video>
    <div class="footer">
      <table class="sound-table one">
        <thead>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              <img :width="item._avatar.toString()" :src="item.avatar" alt="" />
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.name }}
              <p v-text="item._name ? item._name : ''"></p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.size }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.capacity }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.distance }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.summary }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.method }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">{{ item.number }}</td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.navigation }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">{{ item.grade }}</td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">{{ item.mixer }}</td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.positionAndLED }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.coupling }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="sound-table two">
        <thead>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              <img :width="`${item._avatar}%`" :src="item.avatar" alt="" />
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.name }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.size }}</td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.suttle }}</td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.vision }}</td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.animationForm }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.Microphone }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.Trumpet }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.intelligentNoiserRduction }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.echoCanceler }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.automaticLowLightCorrection }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.tripodFrame }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.compatibility }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      soundList: [
        {
          id: 0,
          avatar: "",
          _avatar: "0",
          name: "",
          size: "サイズ（mm）",
          capacity: "バッテリー容量",
          distance: "Bluetooth最大距離",
          summary: "EMEETカスケードラインを接続して実現",
          method: "接続方法",
          number: "対応人数",
          navigation: "バッテリー航続",
          grade: "双方向会话機能",
          mixer: "オーディオミキサー機能",
          positionAndLED: "位置検出機能&LED指示",
          coupling: "連結機能（連結ケ－プル別売）",
        },
        {
          id: 1,
          avatar: require("../../assets/speaker/Mo.402af57d.png"),
          _avatar: "35%",
          name: "EMEET Mo",
          size: "80 * 80 * 40.7",
          capacity: "/",
          distance: "/",
          summary: "/",
          method: "USB",
          number: "5名様まで",
          navigation: "/",
          grade: "●●● ○ ",
          mixer: "エコー・キャンセリング",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 2,
          avatar: require("../../assets/speaker/Luna.07e678a6.png"),
          _avatar: "55%",
          name: "EMEET",
          _name: "Luna Lite",
          size: "120 * 120 * 36.5",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "6名様まで",
          navigation: "15h",
          grade: "●●● ○",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 3,
          avatar: require("../../assets/speaker/Luna2.8ff68455.png"),
          _avatar: "55%",
          name: "EMEET",
          _name: "Luna",
          size: "120 * 120 * 36.5",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "6名様まで",
          navigation: "15h",
          grade: "●●●●",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 4,
          avatar: require("../../assets/speaker/emeet_m2.acd8571f.png"),
          _avatar: "80%",
          name: "EMEET M2	",
          size: "125 * 125 * 35",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "8名様まで",
          navigation: "10h",
          grade: "●●●● ○",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 5,
          avatar: require("../../assets/speaker/m220 _lite.dd833c2c.png"),
          _avatar: "75%",
          name: "M220 Lite",
          size: "125 * 125 * 35",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "16名様まで",
          navigation: "10h",
          grade: "●●●● ○",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 6,
          avatar: require("../../assets/speaker/m2_max.f9b42c8e.png"),
          _avatar: "100%",
          name: "M2 Max",
          size: "188 * 188 * 45",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "2",
          method: "BT, USB",
          number: "16名様まで",
          navigation: "12h",
          grade: "●●●●●",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
      ],
      cameraList: [
        {
          id: 0,
          avatar: "",
          _avatar: "0",
          name: "",
          size: "サイズ（mm）",
          suttle: "正味重量 (g)",
          vision: "視野",
          animationForm: "動画形式",
          Microphone: "マイクロフォン",
          Trumpet: "トランペット",
          intelligentNoiserRduction: "インテリジェントなノイズリダクション",
          echoCanceler: "エコー・キャンセリング",
          automaticLowLightCorrection: "自動ローライト補正",
          tripodFrame: "さんきゃくだい",
          compatibility: "互換性",
        },
        {
          id: 1,
          avatar: require("../../assets/speaker/c960.1da55318.png"),
          _avatar: "60",
          name: "EMEET C960",
          size: "133*75*54",
          suttle: "157",
          vision: "定对焦:f=4.6mm, FOV: 90",
          animationForm: "1080P/30fps",
          Microphone: "2",
          Trumpet: "",
          intelligentNoiserRduction: "●",
          echoCanceler: "",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
        {
          id: 2,
          avatar: require("../../assets/speaker/nova.86dc8f63.png"),
          _avatar: "80",
          name: "EMEET NOVA",
          size: "100*65*55",
          suttle: "144",
          vision: "自動フォーカ ス:FOV: 90°",
          animationForm: "1080P/30fps",
          Microphone: "2",
          Trumpet: "",
          intelligentNoiserRduction: "●",
          echoCanceler: "",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
        {
          id: 3,
          avatar: require("../../assets/speaker/c980.2af92600.png"),
          _avatar: "60",
          name: "EMEET C980 Pro",
          size: "158*60*70",
          suttle: "244",
          vision: "固定焦点:f=4.6mm, FOV:90°",
          animationForm: "1080P/30fps",
          Microphone: "4",
          Trumpet: "2*1W",
          intelligentNoiserRduction: "●",
          echoCanceler: "●",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
        {
          id: 4,
          avatar: require("../../assets/speaker/Jupiter.35d71d72.png"),
          _avatar: "70",
          name: "EMEET Jupiter",
          size: "56*80*63",
          suttle: "350",
          vision: "AIスマート人型追跡 FOV: 90°",
          animationForm: "1080P/30fps",
          Microphone: "4",
          Trumpet: "2*1W",
          intelligentNoiserRduction: "●",
          echoCanceler: "●",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.header {
  img {
    vertical-align: bottom;
  }
}

.banner {
  width: 100vw;
  height: 56vw;
  background: url("../../assets/mobileSpeaker/banner.png") no-repeat center;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    width: 75.4667vw;
    text-align: center;
    font-size: 3.7333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.0667vw;
    color: #ffffff;
    margin-bottom: 3.2vw;
  }
  p {
    width: 83.4667vw;
    text-align: center;
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 5.3333vw;
    color: #ffffff;
  }
}

.content {
  overflow: hidden;
  padding: 1.0667vw 0 8vw 0;
  li {
    margin: 3.2vw 4.2667vw;
    height: 112vw;
    background-size: cover !important;
    overflow: hidden;
    h2 {
      font-size: 4.2667vw;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 5.8667vw;
      color: #333333;
      text-align: center;
      margin: 8vw 0 5.3333vw 0;
    }
    p {
      padding: 0 3.7333vw;
      font-size: 3.2vw;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 6.9333vw;
      color: #333333;
      text-align: center;
    }
  }
  li:first-child {
    background: url("../../assets/mobileSpeaker/one.png") no-repeat center;
  }
  li:nth-child(2) {
    background: url("../../assets/mobileSpeaker/two.png") no-repeat center;
  }
  li:nth-child(3) {
    background: url("../../assets/mobileSpeaker/three.png") no-repeat center;
  }
  li:last-child {
    background: url("../../assets/mobileSpeaker/four.png") no-repeat center;
    height: 120vw !important;
    margin-bottom: 0 !important;
  }
}
video {
  vertical-align: bottom;
}

.footer {
  width: 100vw;
  padding: 0 4.2667vw;
  box-sizing: border-box;
  margin-bottom: 8vw;
  .sound-table {
    width: 100%;
    font-size: 3.2vw !important;
    thead {
      td {
        font-weight: bold;
        p {
          font-weight: inherit;
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          font-weight: bold;
        }
      }
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      margin: 2.6667vw 0;
    }
    td {
      width: 12vw;
      text-align: center;
      line-break: anywhere;
    }
  }
  .two {
    td {
      width: 17.3333vw !important;
    }
  }
}
</style>
