<template>
  <div>
    <div class="header">
      <img width="100%" src="../../assets/speaker/emeet-header@2x.png" alt="" />
    </div>
    <div class="banner">
      <div class="banner-box">
        <p>{{ this.$t("EMEET.EmetOptimized") }}</p>
        <p style="width: 90%">
          {{ this.$t("EMEET.TheExclusiveAI") }}
        </p>
      </div>
    </div>
    <div class="container content">
      <ul style="width:1200px" class="blurb container">
        <li>
          <div class="blurb-img">
            <img
              width="100%"
              src="../../assets/speaker/369camera.7f446bdf.png"
              alt=""
            />
          </div>
          <div class="blurb-details">
            <p>{{ this.$t("EMEET.ToSee") }}</p>
            <p>
              {{ this.$t("EMEET.The360Degree") }}
            </p>
          </div>
        </li>
        <li>
          <div class="blurb-details">
            <p>{{ this.$t("EMEET.SoundClearly") }}</p>
            <p>
              {{ this.$t("EMEET.SmartOmnidirectional") }}
            </p>
          </div>
          <div class="blurb-img">
            <img
              width="100%"
              src="../../assets/speaker/369camera.7f446bdf(2).png"
            />
          </div>
        </li>
        <li>
          <div class="blurb-img">
            <img
              width="100%"
              src="../../assets/speaker/369camera.7f446bdf(1).png"
              alt=""
            />
          </div>
          <div class="blurb-details">
            <p>{{ this.$t("EMEET.ListenToAll") }}</p>
            <p class="music-details">
              <span>{{ this.$t("EMEET.The10W90dB") }} </span>
              <span>
                {{ this.$t("EMEET.originalCreation") }}
              </span>
            </p>
          </div>
        </li>
        <li>
          <div class="blurb-details">
            <p>{{ this.$t("EMEET.MaximizingPerformance") }}</p>
            <p>
              {{ this.$t("EMEET.BestProfessional") }}
            </p>
          </div>
          <div class="blurb-img">
            <img
              width="100%"
              src="../../assets/speaker/369camera.7f446bdf(3).png"
            />
          </div>
        </li>
      </ul>
      <video
        class="speaker-video"
        src="https://enway-official.s3.ap-northeast-1.amazonaws.com/enway-video/3-2/369camera_video.13946992.mp4"
        loop
        controls
        muted
        autoplay
      ></video>
    </div>
    <div class="footer">
      <table class="sound-table container">
        <thead>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              <img :width="item._avatar.toString()" :src="item.avatar" alt="" />
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.name }}
              <p v-text="item._name ? item._name : ''"></p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.size }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.capacity }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.distance }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.summary }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.method }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">{{ item.number }}</td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.navigation }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">{{ item.grade }}</td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">{{ item.mixer }}</td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.positionAndLED }}
            </td>
          </tr>
          <tr>
            <td v-for="item in soundList" :key="item.id">
              {{ item.coupling }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="sound-table container">
        <thead>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              <img :width="item._avatar" :src="item.avatar" alt="" />
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.name }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.size }}</td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.suttle }}</td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">{{ item.vision }}</td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.animationForm }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.Microphone }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.Trumpet }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.intelligentNoiserRduction }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.echoCanceler }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.automaticLowLightCorrection }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.tripodFrame }}
            </td>
          </tr>
          <tr>
            <td v-for="item in cameraList" :key="item.id">
              {{ item.compatibility }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      soundList: [
        {
          id: 0,
          avatar: "",
          _avatar: "0",
          name: "",
          size: "サイズ（mm）",
          capacity: "バッテリー容量",
          distance: "Bluetooth最大距離",
          summary: "EMEETカスケードラインを接続して実現",
          method: "接続方法",
          number: "対応人数",
          navigation: "バッテリー航続",
          grade: "双方向会话機能",
          mixer: "オーディオミキサー機能",
          positionAndLED: "位置検出機能&LED指示",
          coupling: "連結機能（連結ケ－プル別売）",
        },
        {
          id: 1,
          avatar: require("../../assets/speaker/Mo.402af57d.png"),
          _avatar: "35%",
          name: "EMEET Mo",
          size: "80 * 80 * 40.7",
          capacity: "/",
          distance: "/",
          summary: "/",
          method: "USB",
          number: "5名様まで",
          navigation: "/",
          grade: "●●● ○ ",
          mixer: "エコー・キャンセリング",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 2,
          avatar: require("../../assets/speaker/Luna.07e678a6.png"),
          _avatar: "55%",
          name: "EMEET",
          _name: "Luna Lite",
          size: "120 * 120 * 36.5",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "6名様まで",
          navigation: "15h",
          grade: "●●● ○",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 3,
          avatar: require("../../assets/speaker/Luna2.8ff68455.png"),
          _avatar: "55%",
          name: "EMEET",
          _name: "Luna",
          size: "120 * 120 * 36.5",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "6名様まで",
          navigation: "15h",
          grade: "●●●●",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 4,
          avatar: require("../../assets/speaker/emeet_m2.acd8571f.png"),
          _avatar: "80%",
          name: "EMEET M2	",
          size: "125 * 125 * 35",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "8名様まで",
          navigation: "10h",
          grade: "●●●● ○",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 5,
          avatar: require("../../assets/speaker/m220 _lite.dd833c2c.png"),
          _avatar: "75%",
          name: "M220 Lite",
          size: "125 * 125 * 35",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "1",
          method: "BT, USB, AUX",
          number: "16名様まで",
          navigation: "10h",
          grade: "●●●● ○",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
        {
          id: 6,
          avatar: require("../../assets/speaker/m2_max.f9b42c8e.png"),
          _avatar: "100%",
          name: "M2 Max",
          size: "188 * 188 * 45",
          capacity: "2600 mAh",
          distance: "10m (33ft)",
          summary: "2",
          method: "BT, USB",
          number: "16名様まで",
          navigation: "12h",
          grade: "●●●●●",
          mixer: "VoiceIA ® 音声アルゴリズム",
          positionAndLED: "√",
          coupling: "EMEETカスケードラインを接続して実現",
        },
      ],
      cameraList: [
        {
          id: 0,
          avatar: "",
          _avatar: "0",
          name: "",
          size: "サイズ（mm）",
          suttle: "正味重量 (g)",
          vision: "視野",
          animationForm: "動画形式",
          Microphone: "マイクロフォン",
          Trumpet: "トランペット",
          intelligentNoiserRduction: "インテリジェントなノイズリダクション",
          echoCanceler: "エコー・キャンセリング",
          automaticLowLightCorrection: "自動ローライト補正",
          tripodFrame: "さんきゃくだい",
          compatibility: "互換性",
        },
        {
          id: 1,
          avatar: require("../../assets/speaker/c960.1da55318.png"),
          _avatar: "60",
          name: "EMEET C960",
          size: "133*75*54",
          suttle: "157",
          vision: "定对焦:f=4.6mm, FOV: 90",
          animationForm: "1080P/30fps",
          Microphone: "2",
          Trumpet: "",
          intelligentNoiserRduction: "●",
          echoCanceler: "",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
        {
          id: 2,
          avatar: require("../../assets/speaker/nova.86dc8f63.png"),
          _avatar: "80",
          name: "EMEET NOVA",
          size: "100*65*55",
          suttle: "144",
          vision: "自動フォーカ ス:FOV: 90°",
          animationForm: "1080P/30fps",
          Microphone: "2",
          Trumpet: "",
          intelligentNoiserRduction: "●",
          echoCanceler: "",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
        {
          id: 3,
          avatar: require("../../assets/speaker/c980.2af92600.png"),
          _avatar: "60",
          name: "EMEET C980 Pro",
          size: "158*60*70",
          suttle: "244",
          vision: "固定焦点:f=4.6mm, FOV:90°",
          animationForm: "1080P/30fps",
          Microphone: "4",
          Trumpet: "2*1W",
          intelligentNoiserRduction: "●",
          echoCanceler: "●",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
        {
          id: 4,
          avatar: require("../../assets/speaker/Jupiter.35d71d72.png"),
          _avatar: "70",
          name: "EMEET Jupiter",
          size: "56*80*63",
          suttle: "350",
          vision: "AIスマート人型追跡 FOV: 90°",
          animationForm: "1080P/30fps",
          Microphone: "4",
          Trumpet: "2*1W",
          intelligentNoiserRduction: "●",
          echoCanceler: "●",
          automaticLowLightCorrection: "●",
          tripodFrame: "●",
          compatibility: "Zoom, Skype, Teams, Google hangout and GoToMeeting",
        },
      ],
    };
  },
  created() {
    console.log(1);
    let Ele = document.querySelectorAll("tr");
    for (let i = 0; i < Ele.length; i++) {
      Ele[i].firstChild.classList.add("act");
      console.log(Ele[i].firstChild.classList.add("act"));
    }
  },
  mounted() {},
  methods: {},
};
</script>
    
<style scoped lang="less">
.header {
  background-color: rgb(242, 242, 242);
  img {
    vertical-align: bottom;
  }
}
.banner {
  background: url("../../assets/speaker/Emeet@2x.png") no-repeat center;
  background-size: cover;

  .banner-box {
    height: 534px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    p:first-child {
      width: 998px;
      font-size: 50px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      margin-bottom: 34px;
    }
    p:last-child {
      width: 513px !important;
      font-size: 18px;
      font-family: "Meiryooo";
      font-weight: 500;
      line-height: 30px;
      color: #ffffff;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  margin-top: 40px !important;
  li {
    height: 600px;
    box-sizing: border-box;
    padding: 40px;
    margin-bottom: 40px;
    display: flex;
    .blurb-img {
      flex-shrink: 0;
      img {
        transition: 0.5s;
        vertical-align: bottom;
        &:hover {
          transform: scale(1.2, 1.2);
        }
      }
    }
    .blurb-details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        width: 513px;
      }
      p:first-child {
        font-size: 50px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 66px;
        color: #000000;
        margin-bottom: 60px;
      }
      p:last-child {
        font-size: 18px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 30px;
        color: #000000;
      }
      .music-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 30px;
          color: #000000;
        }
        span:first-child {
          margin-bottom: 40px;
        }
      }
    }
  }
  .speaker-video {
    margin-bottom: 100px;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .sound-table {
    width: 1500px;
    margin-bottom: 70px !important;
    thead {
      font-size: 18px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 30px;
      color: #000000;
      td {
        font-weight: bold;
      }
    }
    tbody {
      tr {
        td:first-child {
          font-weight: bold;
        }
      }
      td {
        height: 100px;
      }
    }
    td {
      padding: 0 40px;
      width: 138px;
      vertical-align: middle;
      img {
        vertical-align: bottom;
      }
    }
  }
}
</style>
    